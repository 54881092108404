$white: #fff;
$black: #000;
$primary: #DA2424;
$secondary: #A10000;

.navigation-ul {
    li {
        a {
            transition: all 0.2s ease-in-out;
            position: relative;
            padding: 2px 0;
            &::after {
                content: "";
                position: absolute;
                border: solid 0 $primary;
                height: 18px;
                width: 30px;
                top: 0;
                left: -18px;
                transition: all 0.2s ease-in-out;
                max-width: 0;
                max-height: 0; }
            &::before {
                content: "";
                position: absolute;
                border: solid 0 $primary;
                height: 18px;
                width: 30px;
                bottom: 0;
                right: -18px;
                transition: all 0.2s ease-in-out;
                max-width: 0;
                max-height: 0; } } }
    li.nav-active {
        a {
            color: $primary !important;
            transition: all 0.2s ease-in-out;
            &::after {
                border-width: 3px 0 0 3px;
                max-width: 30px;
                max-height: 18px; }
            &::before {
                border-width: 0 3px 3px 0;
                max-width: 30px;
                max-height: 18px; } } } }

header.about-us,
header.services,
header.clients {
    .navigation-ul {
        li {
            a {
                color: $white; }
            &.nav-active {
                a {
                    color: $white !important;
                    &::after,
                    &::before {
                        border-color: $white; } } } } } }

.navigation-ul-mobile {
    li.nav-active {
        position: relative;
        a {
            color: $primary !important;
            transition: all 0.2s ease-in-out;
            &::after {
                content: '';
                position: absolute;
                right: 0;
                top: 0;
                width: 3px;
                height: 100%;
                bottom: 0;
                background-color: $primary; } } } }
