$white: #fff;
$black: #000;
$primary: #DA2424;
$secondary: #A10000;

.g-d-f {
    display: flex; }
.g-d-f.j_c-f_e {
    justify-content: flex-end; }
.g-d-f.j_c-c {
    justify-content: center; }
.g-t_d-n {
    text-decoration: none; }
.c-white {
    color: $white; }
.c-black {
    color: $black; }
.c-primary {
    color: $primary; }
.c-secondary {
    color: $secondary; }
.shadow-none {
    box-shadow: none !important; }
.g-full {
    width: 100% !important; }

$spaceamounts: (0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 60, 70, 75, 80, 90, 100);
$sides: ("": "all", "t": "top", "b": "bottom", "l": "left", "r": "right");

@each $space in $spaceamounts {
  @each $prefix, $value in $sides {
    $property: if($prefix == '', '', -#{$value});
    .m#{$prefix}#{$space} {
      margin#{$property}: #{$space}px !important; }
    .p#{$prefix}#{$space} {
      padding#{$property}: #{$space}px !important; } } }

$font_weight: (100, 200, 300, 400, 500, 600, 700, 800, 900);
@each $x in $font_weight {
    .f_w-#{$x} {
        font-weight: $x !important; } }
