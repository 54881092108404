header {
    .d-padding {
        padding-top: 10px;
        padding-bottom: 10px; } }
.banner-image {
    position: relative;
    // &::after
    //     content: ''
    //     position: absolute
    //     top: -100px
    //     height: 200px
    //     width: 100px
    //     right: -50px
    //     background-image: url("/img/curl1.png")
    // .img-red-area
    //     &::after
    //         content: ''
    //         position: absolute
    //         top: -77px
    //         height: 150px
    //         width: 100px
    //         left: -10px
    //         background-image: url("/img/circle-small.png")
 }    //         mix-blend-mode: multiply
.bg-banner-number {
    position: relative;
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-image: url("/img/background.png");
        background-size: 100%; } }
.d-banner-number {
    position: relative;
    &::after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: 35px;
        width: 100%;
        background-color: var(--primary);
        z-index: 0; }
    & > div {
        position: relative;
        z-index: 1;
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-image: url("/img/background.png");
            background-size: 60%; } } }
.d-banner-box {
    position: relative;
    &::after {
        content: '';
        position: absolute;
        width: calc( (100% - 1280px) / 2 );
        height: calc(50% + (35px/2));
        background-color: var(--primary);
        top: 0;
        right: 0;
        bottom: 0; } }
.banner-upper {
    position: relative;
    // &::before
    //     content: ''
    //     position: absolute
    //     top: 15%
    //     height: 195px
    //     width: 105px
    //     left: -60px
    //     background-image: url("/img/curl1.png")
    &::after {
        content: '';
        position: absolute;
        width: calc( (100% - 1280px) / 2 );
        height: 100%;
        background-color: var(--primary);
        top: 0;
        right: 0;
        bottom: 0; } }

.d-curl-img {
    position: relative;
    img {
        position: relative;
        z-index: 1; }
    &::after {
        content: '';
        position: absolute;
        bottom: -100px;
        height: 195px;
        width: 105px;
        left: -45px;
        background-image: url("/img/curl1.png");
        transform: rotate(90deg); } }
.d-red-area-dot {
    & > div {
        position: relative;
        &::after {
            content: '';
            position: absolute;
            top: -75px;
            height: 150px;
            width: 60px;
            left: -60px;
            background-image: url("/img/cross-red.png");
            mix-blend-mode: multiply;
            z-index: 0; }
        &::before {
            content: '';
            position: absolute;
            top: -75px;
            height: 150px;
            width: 50px;
            left: 0px;
            background-image: url("/img/cross-white.png");
            z-index: 0; } } }

.service-hover {
    transition: all 0.3s ease-in-out;
    .line {
        transition: all 0.3s ease-in-out; }
    .process-desc {
        transform: rotateX(90deg);
        min-height: 100%;
        padding: 15px;
        transition: all 0.2s ease-in-out;
        transform-origin: bottom;
        opacity: 0; }
    &:hover {
        background-color: var(--primary) !important;
        color: var(--white) !important;
        box-shadow: 0 10px 40px rgba(0, 0, 0, 0.35);
        transition: all 0.3s ease-in-out;
        .process-desc {
            display: block !important;
            transform: rotateX(0deg);
            transition: all 0.2s ease-in-out;
            transform-origin: bottom;
            opacity: 1; }
        .line {
            background-color: var(--white) !important;
            transition: all 0.3s ease-in-out; } } }

.about-bg-1 {
    background-image: url("/img/background.png");
    background-size: 60%; }
.hr-solutions {
    position: relative;
    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 44.5px;
        width: 3px;
        height: calc(100% - 80px);
        background-image: url("/img/line.png");
        z-index: 0;
        transform: translateY(-50%); } }
