.sub-service-single {
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50px 200px 100px 50px;
    .shadow-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #ffffff;
        top: 30px;
        left: 30px;
        border-radius: 40px;
        z-index: 0; } }
.service-click {
    position: relative;
    &.active {
        color: var(--white) !important;
        background-color: var(--primary) !important;
        .line {
            background-color: var(--white) !important; }
        &::after {
            content: '';
            position: absolute;
            height: 30px;
            width: 30px;
            background-color: var(--primary);
            border-radius: 400px;
            border: 4px solid var(--white);
            left: 50%;
            transform: translateX(-50%);
            bottom: -15px; }
        &::before {
            content: '';
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 200px;
            border: 1px dashed var(--primary); } } }

